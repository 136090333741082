import axios from 'axios'
import { Context, createContext } from 'react'
import { apiClient } from './fileService'

export interface FilePersistService {
  saveFile: (path: string, fileContent: string) => Promise<any>
}

export class HttpFilePersistService implements FilePersistService {
  persistUrl: string

  constructor(persistUrl: string) {
    this.persistUrl = persistUrl
  }

  saveFile(path: string, fileContent: string): Promise<any> {
    return apiClient.post(this.persistUrl, { path: path.trim(), fileContent })
  }
}

export class NoOpFilePersistService implements FilePersistService {
  saveFile(path: string, fileContent: string): Promise<any> {
    return Promise.resolve()
  }
}

export const FilePersistServiceContext: Context<HttpFilePersistService> =
  createContext(new HttpFilePersistService('http://localhost:3001/content'))
