'use client'

import React, { useState } from 'react'
import { getModeAndContent, parseContent } from '../../service/scriptEval'
import StakzFormWidget, { FormWidgetState } from '../stakzFormWidget/layout'
import yaml from 'js-yaml'
import ScriptWidget from '../scriptWidget/layout'
import { ReactElement } from 'react-markdown/lib/react-markdown'

const Border = (props: { children: ReactElement; title: string }) => {
  return (
    <fieldset
      style={{
        border: '1px white solid',
        borderRadius: 4,
        padding: '0.2em 0.8em',
      }}
    >
      <legend
        contentEditable={true}
        style={{
          border: '0px',
          marginLeft: '1em',
          width: 'auto',
          padding: '0.2em',
        }}
      >
        {props.title}
      </legend>
      {props.children}
    </fieldset>
  )
}

export default function StakzWidget(props: {
  stakFile: string
  onUpdate?: (newValue: string) => void
}) {
  const modeAndContent = getModeAndContent(props.stakFile)
  const initialParse =
    modeAndContent.type != 'error'
      ? parseContent(modeAndContent.content, modeAndContent.type)
      : modeAndContent
  const schemaToString = (schema: any, mode: 'yaml' | 'json') => {
    return mode === 'json' ? JSON.stringify(schema, null, 2) : yaml.dump(schema)
  }
  const initialScript = initialParse.type != 'error' ? initialParse.script : ''
  const initialState: FormWidgetState =
    initialParse.type == 'error'
      ? {
          type: 'yaml',
          content: '',
          schema: {},
          errorMessage: initialParse.message,
        }
      : {
          ...initialParse,
          content: schemaToString(initialParse.schema, initialParse.type),
        }

  const [formData, setFormData] = useState(initialState.content)
  const [script, setScript] = useState(initialScript)

  const dispatchUpdate = (newValue: string, type: 'script' | 'form') => {
    const serializeFunc: (_: object) => string =
      initialParse.type === 'json' ? JSON.stringify : yaml.dump
    const parseFunc: (_: string) => any =
      initialParse.type === 'json' ? JSON.parse : yaml.load
    type == 'form'
      ? props.onUpdate?.(serializeFunc({ schema: parseFunc(newValue), script }))
      : props.onUpdate?.(
          serializeFunc({ schema: parseFunc(formData), script: newValue }),
        )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
      }}
    >
      <Border title='Form'>
        <StakzFormWidget
          initialState={initialState}
          dispatchUpdate={newValue => {
            setFormData(newValue)
            dispatchUpdate(newValue, 'form')
          }}
        />
      </Border>
      <Border title='Script'>
        <ScriptWidget
          script={initialScript}
          onUpdate={newScript => {
            setScript(newScript)
            dispatchUpdate(newScript, 'script')
          }}
        />
      </Border>
    </div>
  )
}
