import React from 'react'
import { IAceEditorProps } from 'react-ace'
import dynamic from 'next/dynamic'

const AceEditor = dynamic(
  async () => {
    const ace = await import('react-ace')
    require('ace-builds/webpack-resolver')
    require('ace-builds/src-noconflict/ext-language_tools')
    require('ace-builds/src-noconflict/theme-nord_dark') // Change theme to Nord Dark
    require('ace-builds/src-noconflict/mode-markdown')
    require('ace-builds/src-noconflict/mode-yaml')
    require('ace-builds/src-noconflict/mode-json')
    require('ace-builds/src-noconflict/mode-javascript')
    require('ace-builds/src-noconflict/mode-sh')
    require('ace-builds/src-noconflict/keybinding-vim')
    return ace
  },
  { ssr: false },
)

// var customCompleter = {
//   getCompletions: function (editor, session, pos, prefix, callback) {
//     console.log(editor, session, pos, prefix, callback);
//     // your code
//     /* for example
//      * let TODO = ...;
//      * callback(null, [{name: TODO, value: TODO, score: 1, meta: TODO}]);
//      */
//   },
// };

// export const aceBuilds = ace;
// export const langTools = ace.require("ace/ext/language_tools");

// langTools.addCompleter(customCompleter);
// // langTools.addSnippet('# AddNode\n\
// // snippet addn\n\
// //     {\n\
// //         "nodeName": "${1:node_name}",\n\
// //         "algorithmName": "${2:algo_name}",\n\
// //         "input": []\n\
// //     }\n\
// // ')
export default function Ace(props: IAceEditorProps) {
  return (
    // @ts-ignore
    <AceEditor
      {...props}
      editorProps={{ $blockScrolling: true, ...props.editorProps }}
    />
  )
}
