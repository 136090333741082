import React, { useState } from 'react'
import { useSetAtom } from 'jotai'
import { executeScript as executeScriptAtom } from 'store/commandStore'
import { CliChatResponse } from 'app/api/types/types'

export default function CommandHelper({
  response,
  className,
}: {
  response: CliChatResponse
  className?: string
}) {
  const executeScript = useSetAtom(executeScriptAtom)
  const [commands, setCommands] = useState(
    response.remediation.map(({ command }) => command),
  )

  const handleCommandChange = (index: number, newCommand: string) => {
    const newCommands = [...commands]
    newCommands[index] = newCommand
    setCommands(newCommands)
  }

  const handleCommandExecute = (command: string) => {
    executeScript(command)
  }

  return (
    <div className={'space-y-4 ' + className}>
      <p>{response.explanation}</p>
      <div>
        <p className='font-semibold'>Remediation Commands:</p>
        {response.remediation.map(({ description }, index) => (
          <div className='mb-2 pb-2 pt-2' key={index}>
            <div className='flex items-center space-x-2'>
              <textarea
                value={commands[index]}
                onChange={e => handleCommandChange(index, e.target.value)}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleCommandExecute(commands[index])
                  }
                }}
                className='border border-gray-300 rounded p-2 flex-grow text-wrap break-words'
              />
              <button
                onClick={() => handleCommandExecute(commands[index])}
                className='ml-2 text-blue-500 border border-blue-500 rounded p-2'
              >
                Execute
              </button>
            </div>
            <p>{description}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
