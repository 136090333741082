"use client";

import React from "react";
import {
  UnstyledButton,
  Tooltip,
} from "@mantine/core";
import { CodePlus, Icon, Stack2 } from "tabler-icons-react";
import { useAtom } from "jotai";
import { sideNavExpanded as _sideNavExpanded } from "../../store/stakzStore";
import { activeViewType as activeViewTypeAtom } from "../../store/fileStore";

export type NavbarLink = { icon: Icon; label: string; onClick: () => void, type: "browse" | "editor" };


export default function DoubleNavbar() {
  const [sideNavExpanded, setSideNavExpanded] = useAtom(_sideNavExpanded)
  const _links: NavbarLink[] = [
    {
      icon: Stack2,
      label: "Find Stakz",
      type: "browse",
      onClick: () => {
        if (activeViewType == "browse") {
          setSideNavExpanded(!sideNavExpanded);
        } else {
          setSideNavExpanded(true);
          setActiveViewType("browse");
        }
      },
    },
    {
      icon: CodePlus,
      label: "Create Stakz",
      type: "editor",
      onClick: () => {
        if (activeViewType == "editor") {
          setSideNavExpanded(!sideNavExpanded);
        } else {
          setSideNavExpanded(true);
          setActiveViewType("editor");
        }
      },
    },
  ];

  const [activeViewType, setActiveViewType] = useAtom(activeViewTypeAtom)

  const mainLinks = _links.map((link) => (
    <Tooltip
      label={link.label}
      position="right"
      withArrow
      // transitionDuration={0}
      key={link.label}>
      <UnstyledButton
        variant="filled"
        className={"p-2 mb-1 hover:bg-gray-600 rounded-md border-gray-800" + (activeViewType == link.type ? " bg-gray-600" : "")}
        style={{ backgroundColor: activeViewType == link.type ? "gray" : "transparent" }}
        onMouseDown={link.onClick}
      >
        <link.icon />
      </UnstyledButton>
    </Tooltip>
  ));


  return (<div className="flex flex-col p-2 bg-neutral-800 ">
    {mainLinks}
  </div>
  );
}
