// @ts-nocheck

import { WritableAtom, atom } from 'jotai'
import { parseHtml } from '../service/stakzParser'
import yaml from 'js-yaml'
import { deepEquals } from '@rjsf/utils'

import { atomWithStorage } from 'jotai/utils'
import { parseStakz } from 'service/stakzParser'

export type FragmentType = 'md' | 'stakz' | 'widget'

export type FragmentMetadata = {
  content: string
  fragmentType: FragmentType
  startPos: number
  endPos: number
}

export class MapWithDefault<K, T> extends Map<K, T> {
  default: (_: K) => T

  get(key: K): T {
    if (!this.has(key)) {
      this.set(key, this.default(key))
    }
    return super.get(key) as T
  }

  constructor(defaultFunction: (_: K) => T, entries = []) {
    super(entries)
    this.default = defaultFunction
  }
}

function checkForContextSwitch(
  line: string,
  currentContext: FragmentType,
): FragmentType | false {
  if (currentContext == 'md') {
    if (line.trim().startsWith('<') && !line.trim().startsWith('</')) {
      return { type: 'widget' }
    } else if (line.search('```Stakz') >= 0) {
      return { type: 'stakz' }
    } else {
      return false
    }
  } else if (currentContext == 'stakz') {
    if (line.search('```') >= 0 && line.search('```Stakz') < 0) {
      return { type: 'md' }
    } else {
      return false
    }
  } else {
    if (
      line.trim().endsWith('/>') ||
      line.includes('</') ||
      line.trim().startsWith('```')
    ) {
      return 'md'
    } else {
      return false
    }
  }
}

const _stakzFileContext = atom('')
export const stakzFileContext = atom(
  get => get(_stakzFileContext),
  (get, set, content: string) => {
    set(_stakzFileContext, content)

    const newFragmentMetadata = parseStakz(content)

    const allVars: { [key: string]: any } = newFragmentMetadata
      .filter(m => m.fragmentType == 'widget')
      .flatMap(m => parseHtml(m.content))
      .filter(m => m && m.getAttribute)
      .reduce((partial: { [key: string]: any }, next) => {
        if (next.textContent) {
          // @ts-ignore
          const key = next.getAttribute('var') || next.getAttribute('label')
          if (!key) {
            return partial
          }
          if (next.nodeName == 'JSON' || next.nodeName == 'YAML') {
            const parseFunc = next.nodeName == 'JSON' ? JSON.parse : yaml.load
            try {
              const parsed = parseFunc(next.textContent)
              if (!deepEquals(parsed, dataValuesObj.read[key])) {
                partial[key] = parsed
              }
            } catch (e) {}
          } else if (
            (next.nodeName == 'FIELD' || next.nodeName == 'SELECT') &&
            dataValuesObj.read[key] != next.textContent
          ) {
            partial[key] = next.textContent
          }
        }
        return partial
      }, {})

    const dataCopy = { ...get(dataValuesObj) }
    Object.keys(dataCopy).forEach(key => {
      if (allVars[key] == undefined) {
        delete dataCopy[key]
      }
    })

    set(fragmentMetdata, newFragmentMetadata)
    Object.entries(allVars).forEach(([key, value]) => {
      if (value != dataCopy[key] || get(dataValues.get(key)) == '') {
        set(dataValues.get(key), value)
      }
    })
    dataValues.keys().forEach(key => {
      if (allVars[key] == undefined) {
        dataValues.delete(key)
      }
    })

    set(dataValuesObj, allVars)
  },
)

export const fragmentMetdata = atom<FragmentMetadata[]>([])
export const expandedSideNav = atom(true)
export var stakzServerBaseUrl = 'http://localhost:3001'
const _dataVals = new MapWithDefault<string, any>(_ => atom(''))
export const dataValues = new MapWithDefault<
  string,
  WritableAtom<any, any, any>
>(key =>
  atom(
    get => get(_dataVals.get(key)),
    (get, set, value) => {
      set(_dataVals.get(key), value)
      set(dataValuesObj, { ...get(dataValuesObj), [key]: value })
    },
  ),
)

export const dataValuesObj = atom<{
  [key: string]: Array<any> | object | string | number | boolean | undefined
}>({})
export type ViewType = 'editor' | 'preview' | 'split'
export const viewType = atom<ViewType>('preview')
export const executionOutput = atom('')
export const executionStatus = atom<'success' | 'failure'>('success')

const _newScriptHash = atom('')
export const newScriptHash = atom(get => get(_newScriptHash))
export const scriptResultsInitiated = atom(null, (_, set) => {
  // random string
  set(_newScriptHash, Math.random().toString(36).substring(7))
})

export const sideNavExpanded = atom(false)
export const stakzServerHealthy = atom(false)
export const token = atomWithStorage('token', '')
export const activeBottomView: Atom<'prompt-engineering' | 'terminal'> =
  atom('prompt-engineering')
