'use client'
import React, { useEffect, useState } from 'react'
import { Box, Button } from '@mantine/core'
import { FaPlay, FaRegClipboard } from 'react-icons/fa'
import {
  dataValuesObj,
  activeBottomView,
  scriptResultsInitiated,
} from 'store/stakzStore'
import scriptToFunction from 'service/scriptEval'
import { useIsClient } from 'service/hooks/customHooks'
import { useSetAtom, useAtomValue } from 'jotai'
import AceEditor from 'components/ace-editor'
import { hash, scriptResults } from 'store/commandStore'

export default function ScriptWidget(props: {
  script: string
  onUpdate?: (newValue: string) => void
}) {
  const data = useAtomValue(dataValuesObj)
  const setActiveTab = useSetAtom(activeBottomView)
  const client = useIsClient()
  const [script, setScript] = useState(props.script)
  const setResult = useSetAtom(scriptResults)
  const setScriptResultsInitiated = useSetAtom(scriptResultsInitiated)
  const [autoReloadScript, setAutoReloadScript] = useState(false)

  function evaluateScript() {
    const result = scriptToFunction(script, data)
    setResult({ result, scriptId: hash(script).toString() })
    if (result?.type === 'success') {
      navigator.clipboard.writeText(result.text)
    }
  }

  useEffect(() => {
    setScript(props.script)
  }, [props.script])

  useEffect(() => {
    if (autoReloadScript) {
      evaluateScript()
    }
  }, [data])

  function focusTerminal() {
    setActiveTab('terminal')
    setScriptResultsInitiated()
    const terminal = document.getElementById('terminal')
    if (terminal) {
      terminal.focus()
    }
  }

  function evaluateScriptAndFocusTerminal() {
    evaluateScript()
    focusTerminal()
    setAutoReloadScript(true)
  }

  return (
    <Box>
      {client && (
        <div className='relative flex'>
          <div className='flex flex-column'>
            <Button
              className='m-2'
              onClick={evaluateScriptAndFocusTerminal}
              variant='subtle'
              color='green'
              radius='md'
              size='lg' // Increase the size of the button
              style={{ padding: '0.75em', position: 'relative' }} // Adjust padding to ensure the icon is not cut off
            >
              <FaPlay />
              <FaRegClipboard
                style={{
                  position: 'absolute',
                  top: '30px',
                  left: '26px',
                  fontSize: '0.5em',
                }}
              />
            </Button>
          </div>
          <AceEditor
            value={script}
            highlightActiveLine={false}
            className='bg-neutral-800 border-none flex-1'
            mode='javascript'
            wrapEnabled={true}
            minLines={10}
            setOptions={{ enableLiveAutocompletion: true }}
            theme='nord_dark'
            maxLines={60}
            onChange={newScript => {
              setScript(newScript)
              props.onUpdate?.(newScript)
            }}
            name='ScriptWidget'
            editorProps={{
              $blockScrolling: true,
            }}
          />
        </div>
      )}
    </Box>
  )
}
