// @ts-nocheck
import yaml from 'js-yaml'

const stakzStartRegex = /```[\w]*Stakz\.(json|yaml)/g
type StakzParse = {
  type: 'json' | 'yaml'
  schema: any
  script: string
  content: string
}

export type StakzParseError = {
  type: 'error'
  message: string
}

export type StakzExecution = {
  type: 'success'
  text: string
}

export default function evaluateScript(
  startingScript: string,
  data: object,
): StakzExecution | StakzParseError {
  var doctoredScript = `
    const data = ${JSON.stringify(data)};
  `
  for (const key in data) {
    if (key != '') {
      doctoredScript += `const ${key} = data.${key};\n`
    }
  }
  doctoredScript += startingScript
  try {
    const func: Function = Function(doctoredScript)
    const res = func()
    return {
      type: 'success',
      text: res,
    }
  } catch (e) {
    return {
      type: 'error',
      message: e.message ?? 'unknown error',
    }
  }
}

export function getModeAndContent(
  text: string,
): { type: 'json' | 'yaml'; content: string } | StakzParseError {
  const results = stakzStartRegex.exec(text)
  stakzStartRegex.lastIndex = 0
  if (
    !results ||
    !results[1] ||
    (results[1] != 'json' && results[1] != 'yaml')
  ) {
    return {
      type: 'error',
      message: 'Expected Stakfile to start with ```Stakz.json or ```Stakz.yaml',
    }
  }
  const lines = text.split(/\r?\n/)
  const content = lines.slice(1, lines.length - 1).join('\n')
  return {
    type: results[1],
    content: content,
  }
}

export function parseContent(
  content: string,
  mode: 'json' | 'yaml',
): StakzParse | StakzParseError {
  try {
    const parsedContent =
      mode === 'json' ? JSON.parse(content) : yaml.load(content)
    if (!parsedContent.schema || !parsedContent.script) {
      return {
        type: 'error',
        message: 'missing either "schema" or "script" section in Stakfile',
      }
    }
    return {
      type: mode,
      schema: parsedContent.schema,
      script: parsedContent.script,
      content,
    }
  } catch (e) {
    return {
      type: 'error',
      message: e.message ?? 'unknown error',
    }
  }
}

export function read(
  content: string,
  mode: 'json' | 'yaml',
): { type: 'success'; content: object } | StakzParseError {
  try {
    const parsedContent =
      mode === 'json'
        ? JSON.parse(content)
        : yaml.load(content, {
            onWarning: e => {
              throw e
            },
          })
    if (parsedContent !== undefined) {
      return {
        type: 'success',
        content: parsedContent,
      }
    } else {
      return { type: 'error', message: 'content undefined' }
    }
  } catch (e) {
    return { type: 'error', message: e.message ?? 'unknown error' }
  }
}
