'use client'

import React, { useEffect } from 'react'

import Form from '@rjsf/bootstrap-4'

import validator from '@rjsf/validator-ajv8'
import Ajv from 'ajv'

import { useReducer } from 'react'
import { read } from '../../service/scriptEval'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { ErrorBoundary } from 'react-error-boundary'
import _ from 'lodash'

import { Alert } from '@mantine/core'
import { AlertCircle, Check } from 'tabler-icons-react'
import { useAtom } from 'jotai'

import { dataValuesObj } from '../../store/stakzStore'

export type FormWidgetState = {
  type: 'json' | 'yaml'
  content: string
  schema: object
  errorMessage?: string
  form?: ReactElement
}

export default function StakzFormWidget(props: {
  initialState: FormWidgetState
  dispatchUpdate: (s: string) => void
}) {
  const [formData, setFormData] = useAtom(dataValuesObj)
  const ajv = new Ajv()

  const reducer = (
    state: FormWidgetState,
    action: { newValue: string },
    isInitial: boolean = false,
  ) => {
    state.errorMessage = undefined
    state.content = action.newValue

    const parseResult = read(action.newValue, state.type)
    if (parseResult.type == 'error') {
      state = { ...state, errorMessage: parseResult.message }
    } else if (ajv.validateSchema(parseResult.content)) {
      if (!isInitial) {
        props.dispatchUpdate(state.content)
      }
      state.form = (
        // @ts-ignore
        <Form
          schema={parseResult.content}
          validator={validator}
          formData={formData}
          onChange={e => {
            setFormData(e.formData)
          }}
          onSubmit={() => {
            // fetch("localhost:3000", { method: 'POST', body: JSON.stringify(state.evalScript) })
          }}
        />
      )
      state = { ...state, schema: parseResult.content }
    } else {
      state = {
        ...state,
        errorMessage: ajv.errorsText(ajv.errors),
        schema: parseResult.content,
      }
    }
    return state
  }

  const initialState = reducer(
    props.initialState,
    {
      newValue: props.initialState.content,
    },
    true,
  )

  const [state, dispatch] = useReducer(reducer, initialState)
  useEffect(() => {
    dispatch({ newValue: initialState.content })
  }, [props.initialState])

  return (
    <div style={{ display: 'flex', gap: '1em', flexDirection: 'column' }}>
      {state.errorMessage ? (
        <Alert
          icon={<AlertCircle size='1rem' />}
          title='Invalid Schema'
          color='red'
          style={{
            display: 'block',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {state.errorMessage}
        </Alert>
      ) : (
        <Alert icon={<Check size='1rem' />} title='Schema Valid' color='green'>
          <div />
        </Alert>
      )}
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, padding: '4px' }}>
          {/* @ts-ignore */}
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            resetKeys={[state.content]}
          >
            {state.form}
          </ErrorBoundary>
        </div>
      </div>
    </div>
  )
}

function ErrorFallback({ error }: { error: Error }) {
  return (
    <div>
      <p>Something went wrong, please check your json schema</p>
      <pre>{error.message}</pre>
    </div>
  )
}
