'use client'

import React, { useEffect } from 'react'

import 'bootstrap/dist/css/bootstrap.css'
import '../styles/globals.css'
import '@mantine/core/styles.css'

import { ColorSchemeScript, MantineProvider } from '@mantine/core'
import DoubleNavbar from './navbar/layout'
import HeaderSearch from './header/layout'
import axios from 'axios'
import { useAtomValue, useSetAtom } from 'jotai'
import { stakzServerHealthy, token as tokenAtom } from 'store/stakzStore'
import { usePathname } from 'next/navigation'
import { currentFile as currentFileAtom } from 'store/fileStore'
import MainWrapper from './mainWrapper/layout'

import { activeViewType as activeViewTypeAtom } from 'store/fileStore'

export default function RootLayout({
  children,
}: {
  children: React.ReactNode
}) {
  const activeViewType = useAtomValue(activeViewTypeAtom)
  const setStakzServerHealthy = useSetAtom(stakzServerHealthy)
  const token = useAtomValue(tokenAtom)

  function checkStakzServerHealth() {
    axios
      .get('http://localhost:3001/echo', {
        headers: { Authorization: `${token}` },
      })
      .then(() => {
        setStakzServerHealthy(true)
      })
      .catch(() => {
        setStakzServerHealthy(false)
      })
  }

  useEffect(() => {
    const interval = setInterval(checkStakzServerHealth, 5000)
    return () => clearInterval(interval) // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [token])

  const path = usePathname()
  const setCurrentFile = useSetAtom(currentFileAtom)
  const segments = path.split('/')

  useEffect(() => {
    if (segments[1] == 'browse') {
      if ((segments?.length ?? 0) >= 4) {
        setCurrentFile({
          org: segments[2],
          repo: segments[3],
          path: segments.slice(4).join('/'),
          type: 'github',
        })
      }
    } else if (segments[1] == 'editor') {
      const path = segments.slice(2).join('/')
      if (path == '') {
        return
      }
      setCurrentFile({
        path: segments.slice(2).join('/'),
        saved: true,
        type: 'local',
      })
    }
  }, [path])
  checkStakzServerHealth()
  return (
    <html lang='en'>
      <head>
        <meta
          name='viewport'
          content='minimum-scale=1, initial-scale=1, width=device-width'
        />
        <ColorSchemeScript />
      </head>
      <body className='h-screen w-screen'>
        <MantineProvider defaultColorScheme='dark'>
          <div className='size-full flex flex-col justify-center'>
            <HeaderSearch />
            <div className='flex w-full flex-grow-1 flex-shrink-1 min-h-0'>
              <div className='border-r-2 border-neutral-700'>
                <DoubleNavbar />
              </div>
              <div className='size-full flex-grow-1 flex-shrink-1 overflow-hidden'>
                {activeViewType == 'editor' || segments.length > 2 ? (
                  <MainWrapper />
                ) : (
                  children
                )}
              </div>
            </div>
          </div>
        </MantineProvider>
      </body>
    </html>
  )
}
