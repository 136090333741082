'use client'

import { githubFileService, localFileService, File } from 'app/api/fileService'
import DrilldownContainer from 'app/sidenav/layout'
import {
  currentFile as currentFileAtom,
  currentFiles,
  isLoadingPrompt as isLoadingPromptAtom,
} from 'store/fileStore'
import MainContent from 'app/main/layout'
import React, { useRef, useState } from 'react'
import { LoadingOverlay, UnstyledButton } from '@mantine/core'
import { useAtomValue, useSetAtom, useAtom } from 'jotai'
import { stakzFileContext } from 'store/stakzStore'
import DataPreview from 'app/dataPreview/layout'
import { sideNavExpanded } from '../../store/stakzStore'
import Terminal from 'components/terminal/terminalWrapper'
import { MdDataObject } from 'react-icons/md'

export default function MainWrapper() {
  const files = useAtomValue<File[]>(currentFiles)
  const setContent = useSetAtom(stakzFileContext)
  const isSidenavExpanded = useAtomValue(sideNavExpanded)
  const [currentFile, setCurrentFile] = useAtom(currentFileAtom)
  const isLoadingPrompt = useAtomValue(isLoadingPromptAtom)

  const [terminalHeight, setTerminalHeight] = useState(25) // percentage
  const [dataPreviewWidth, setDataPreviewWidth] = useState(25) // percentage
  const [isDataPreviewVisible, setIsDataPreviewVisible] = useState(false)

  const verticalRef = useRef<HTMLDivElement>(null)
  const horizontalRef = useRef<HTMLDivElement>(null)

  const handleMouseDownVertical = (e: React.MouseEvent) => {
    e.preventDefault()
    document.addEventListener('mousemove', handleMouseMoveVertical)
    document.addEventListener('mouseup', handleMouseUpVertical)
  }

  const handleMouseMoveVertical = (e: MouseEvent) => {
    if (verticalRef.current) {
      const newHeight = Math.min(
        Math.max(
          ((window.innerHeight - e.clientY) / window.innerHeight) * 100,
          0,
        ),
        100,
      )
      setTerminalHeight(newHeight)
    }
  }

  const handleMouseUpVertical = () => {
    document.removeEventListener('mousemove', handleMouseMoveVertical)
    document.removeEventListener('mouseup', handleMouseUpVertical)
  }

  const handleMouseDownHorizontal = (e: React.MouseEvent) => {
    e.preventDefault()
    document.addEventListener('mousemove', handleMouseMoveHorizontal)
    document.addEventListener('mouseup', handleMouseUpHorizontal)
  }

  const handleMouseMoveHorizontal = (e: MouseEvent) => {
    if (horizontalRef.current) {
      const newWidth = Math.min(
        Math.max(
          ((window.innerWidth - e.clientX) / window.innerWidth) * 100,
          0,
        ),
        100,
      )
      setDataPreviewWidth(newWidth)
    }
  }

  const handleMouseUpHorizontal = () => {
    document.removeEventListener('mousemove', handleMouseMoveHorizontal)
    document.removeEventListener('mouseup', handleMouseUpHorizontal)
  }

  const toggleDataPreview = () => {
    setIsDataPreviewVisible(!isDataPreviewVisible)
  }

  return (
    <div
      className='size-full'
      id='main-wrapper'
      style={{
        display: 'flex',
        width: 'inherit',
        overflowY: 'hidden',
        flex: '1',
        maxHeight: '100%',
      }}
    >
      {isSidenavExpanded && (
        <div
          className='bg-neutral-800'
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '0 0 auto',
            padding: '1em',
            minWidth: '200px',
            overflow: 'hidden',
          }}
        >
          <DrilldownContainer
            items={files.map(file => ({
              path: file.path.split('/'),
              item: file,
              onclick: async () => {
                setCurrentFile(file)
                const content =
                  file.type == 'github'
                    ? await githubFileService.getFileContent(file)
                    : await localFileService.getFileContent(file)
                setContent(content)
              },
            }))}
          />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            flex: `${100 - terminalHeight}%`,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div style={{ flex: '1', overflow: 'hidden' }}>
            {isLoadingPrompt ? (
              <LoadingOverlay visible={true} />
            ) : (
              <MainContent filePath={currentFile.path} />
            )}
          </div>

          <div
            style={{
              width: isDataPreviewVisible ? `${dataPreviewWidth}%` : '0',
              transition: 'width 0.3s ease',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {isDataPreviewVisible && (
              <>
                <div
                  ref={horizontalRef}
                  onMouseDown={handleMouseDownHorizontal}
                  style={{
                    width: '5px',
                    cursor: 'col-resize',
                    backgroundColor: 'rgb(52, 52, 52)',
                  }}
                />
                <div className='h-full flex-auto'>
                  <DataPreview />
                </div>
              </>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <UnstyledButton
              onClick={toggleDataPreview}
              variant='filled'
              className={
                'p-2 m-2 hover:bg-gray-600 rounded-md border-gray-800' +
                (isDataPreviewVisible ? ' bg-gray-600' : '')
              }
              style={{
                backgroundColor: isDataPreviewVisible ? 'gray' : 'transparent',
              }}
            >
              <MdDataObject color='white' />
            </UnstyledButton>
          </div>
        </div>
        <div
          ref={verticalRef}
          onMouseDown={handleMouseDownVertical}
          style={{
            height: '5px',
            cursor: 'row-resize',
            backgroundColor: 'rgb(52, 52, 52)',
          }}
        />
        <div style={{ flex: `${terminalHeight}%`, overflow: 'hidden' }}>
          <Terminal />
        </div>
      </div>
    </div>
  )
}
