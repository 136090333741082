'use client'
import React, { useEffect, useRef, useState } from 'react'
import { PrimitiveAtom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { AiOutlineQuestionCircle, AiOutlineRight } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa'
import { fetchCliChatResponse } from 'service/promptService'
import { CliChatResponse } from 'app/api/types/types'
import CommandHelper from './commandHelper'

import {
  commandHistoryAtom,
  CommandHistoryEntry,
  executeScript as executeScriptAtom,
} from 'store/commandStore'

type HelperLoadingState = {
  state: 'loading'
}

type HelperLoadedState = CliChatResponse & {
  state: 'loaded'
}

type CommandItemProps = {
  commandAtom: PrimitiveAtom<CommandHistoryEntry>
  index: number
  executeScript: (command: string) => void
}

const CommandItem: React.FC<CommandItemProps> = ({
  commandAtom,
  index,
  executeScript,
}) => {
  const [command, setCommand] = useAtom(commandAtom)
  const [commandHelpers, setCommandHelpers] = useState<{
    [key: number]: HelperLoadedState | HelperLoadingState
  }>({})
  const [expandedOutputs, setExpandedOutputs] = useState<{
    [key: number]: boolean
  }>({})
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const commandHelper = commandHelpers[index]
  const isExpanded = expandedOutputs[index]

  const shouldShowExpandIcon = command.output.length > 100

  const toggleOutput = (index: number) => {
    setExpandedOutputs({
      ...expandedOutputs,
      [index]: !expandedOutputs[index],
    })
  }

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [command.command])

  return (
    <div
      key={index}
      className={`relative rounded-s mb-2 flex flex-col m-2 border-2 ${command.status == 'success' ? 'border-green-700/25' : command.status === 'error' ? ' border-red-700/25' : 'border-neutral-700/25'}`}
    >
      <textarea
        ref={textareaRef}
        className='p-2 bg-neutral-900 rounded whitespace-pre-wrap break-words resize-none overflow-hidden'
        value={command.command}
        onChange={e => setCommand({ ...command, command: e.target.value })}
        onKeyDown={e => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            executeScript(command.command)
          }
        }}
        disabled={command.status === 'loading'}
      />
      {command.status === 'loading' && (
        <div className='absolute inset-0 flex items-center justify-center '>
          <FaSpinner className='animate-spin' />
        </div>
      )}
      <div
        className={`flex hover:bg-neutral rounded whitespace-pre-wrap break-words
        ${
          command.status == 'success'
            ? 'bg-green-700/10'
            : command.status == 'error'
              ? 'bg-red-700/10'
              : 'bg-neutral-700/10'
        }`}
      >
        {command.status === 'error' && (
          <button
            disabled={commandHelper?.state === 'loading'}
            className='mr-2 ml-2'
          >
            <AiOutlineQuestionCircle
              className={'text-red-700'}
              onClick={async () => {
                setCommandHelpers({
                  ...commandHelpers,
                  [index]: { state: 'loading' },
                })
                const cliChatResponse = await fetchCliChatResponse({
                  prompt: command.command,
                  selectedModels: ['gpt-4o-mini'],
                  type: 'command',
                })
                setCommandHelpers({
                  ...commandHelpers,
                  [index]: { ...cliChatResponse, state: 'loaded' },
                })
              }}
            />
          </button>
        )}
        {command.output !== '' && (
          <>
            <pre
              className={`p-2 flex-grow ${
                isExpanded ? 'whitespace-pre-wrap' : 'truncate'
              }`}
              style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {command.output}
            </pre>
            {shouldShowExpandIcon && (
              <button onClick={() => toggleOutput(index)} className='ml-2 mr-2'>
                <AiOutlineRight />
              </button>
            )}
          </>
        )}
      </div>

      {commandHelper?.state == 'loaded' && (
        <CommandHelper
          className='p-2 bg-neutral-900/20'
          response={commandHelper}
        />
      )}

      {commandHelper?.state == 'loading' && (
        <div className='flex justify-center items-center p-2'>
          <FaSpinner className='animate-spin' />
        </div>
      )}
    </div>
  )
}

export default function TerminalView() {
  const commandHistory = useAtomValue(commandHistoryAtom)
  const executeScript = useSetAtom(executeScriptAtom)
  return (
    <div className='flex flex-col flex-grow overflow-hidden'>
      <div className='flex flex-col flex-grow overflow-auto'>
        {commandHistory.map((commandAtom, index) => (
          <CommandItem
            key={index}
            commandAtom={commandAtom}
            index={index}
            executeScript={executeScript}
          />
        ))}
      </div>
    </div>
  )
}
