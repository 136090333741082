import { useAtomValue } from 'jotai'
import { useState } from 'react'
import { HiClipboardCopy } from 'react-icons/hi'
import { token as tokenAtom } from 'store/stakzStore'
import { Check } from 'tabler-icons-react'

export default function ServerUnhealthy({ style }: { style?: any }) {
  const [copied, setIsCopied] = useState(false)
  const [copiedDocker, setIsCopiedDocker] = useState(false)
  const token = useAtomValue(tokenAtom)
  return (
    <div className='bg-[rgb(43,43,43)] z-50 absolute p-2 rounded overflow-scroll' style={style}>
      <pre className='text-white text-sm mb-2'>
        The server is not responding. To install, please see&nbsp;
        <a href='https://github.com/writeabyte/stakz-server' target="_blank" className='text-blue-400 underline mb-2'>installation instructions</a>
      </pre>
      <pre className='text-white text-sm mb-2'>
        To run stakz:
      </pre>
      <div
        className={`cursor-pointer bg-neutral-700 p-1 rounded flex ${copied && 'ring ring-green-700'} mb-2`}
        onMouseDown={() => {
          setIsCopied(true)
          setIsCopiedDocker(false)
          navigator.clipboard.writeText(
            `mkdir -p stakz && cd stakz && stakz --execute ${token != '' && `--key ${token}`}`,
          )
        }}
      >
        <pre className='text-white text-sm m-2 flex-1'>
          mkdir -p stakz && cd stakz && stakz --execute {token != '' && `--key ${token}`}
        </pre>
        {copied ? (
          <Check className='m-2 text-green-700 flex-0' />
        ) : (
          <HiClipboardCopy className='m-2 flex-0' />
        )}
      </div>

      <pre className='text-white text-sm mb-2'>
        To run with Docker:
      </pre>
      <div
        className={`cursor-pointer bg-neutral-700 p-1 rounded flex ${copiedDocker && 'ring ring-green-700'}`}
        onMouseDown={() => {
          setIsCopiedDocker(true)
          setIsCopied(false)
          navigator.clipboard.writeText(
            'mkdir -p stakz \\\n && docker run -p 3001:3001 \\\n--mount type=bind,source=./stakz,target=/app/stakz-example \\\n--name stakz-server curtismj1/stakz --execute',
          )
        }}
      >
        <pre className='text-white text-sm m-2 flex-1'>
          mkdir -p stakz && \<br />
          docker run -p 3001:3001 \<br />
          &emsp;--mount type=bind,source=./stakz,target=/app/stakz-example \
          <br />
          &emsp;--name stakz-server curtismj1/stakz
        </pre>
        {copiedDocker ? (
          <Check className='m-2 text-green-700 flex-0' />
        ) : (
          <HiClipboardCopy className='m-2 flex-0' />
        )}
      </div>
    </div>
  )
}
