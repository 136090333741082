// Next.js API route support: https://nextjs.org/docs/api-routes/introduction

// Axios make request to exec endpoint
//
import axios from 'axios'
import { stakzServerBaseUrl } from '../../store/stakzStore'
import { apiClient } from './fileService'

var ws: WebSocket | null = null

export function execScript(script: string) {
  return apiClient.post(`${stakzServerBaseUrl}/execute`, script)
}
