'use client'

import { useAtomValue } from "jotai";
import React, { useState } from "react";
import { AiFillFolder, AiFillFolderOpen } from "react-icons/ai";
import { CiFileOn } from "react-icons/ci";

export interface DrilldownItem<T> {
  onclick: (arg0: any) => void;
  path: string[];
  item: T;
}

import { currentFile as currentFileAtom, } from "../../store/fileStore";

export default function DrilldownContainer<T>(props: {
  items: DrilldownItem<T>[];
  _depth?: number;
  fullPath?: string;
}) {
  const depth = props._depth ?? 0;
  const groupedBy: Map<string, DrilldownItem<T>[]> = props.items.reduce(
    (partial, next) => {
      const key = next.path[0];
      const value = { ...next, path: next.path.slice(1, next.path.length) };
      partial.has(key)
        ? partial.get(key)?.push(value)
        : partial.set(key, [value]);
      return partial;
    },
    new Map<string, DrilldownItem<T>[]>()
  );
  return (
    <>
      {Array.from(groupedBy).map(([key, value]) => (
        <DrilldownNode key={key} text={key} items={value} _depth={depth} fullPath={props.fullPath ? `${props.fullPath}/${key}` : key} />
      ))}
    </>
  );
}

export function DrilldownNode<T>(props: {
  text: string;
  items: DrilldownItem<T>[];
  _depth?: number;
  className?: string;
  fullPath?: string;
}) {
  const depth = props._depth ?? 0;
  const [expanded, setExpanded] = useState(false);
  const isTerminal = props.items.length == 1 && props.items[0].path.length == 0;
  const onclick = isTerminal
    ? props.items[0].onclick
    : () => setExpanded(!expanded);
  const className = props.className;
  const currentFile = useAtomValue(currentFileAtom)
  return (
    <div className={className}>
      <p
        onMouseDown={onclick}
        className={`mr-1 inline-block ${currentFile?.path == props.fullPath ? "font-bold" : ""}`}
        style={{
          paddingLeft: 10 * depth,
          cursor: "pointer",
          verticalAlign: "middle",
        }}
      >
        {!isTerminal ?
          (expanded ? (
            <AiFillFolderOpen
              className="mr-1 inline-block"
              style={{
                verticalAlign: "middle",
                marginBottom: "6px",
                margin: "2px 2px 6px 2px",
              }}
            />
          ) : (
            <AiFillFolder
              className="mr-1 inline-block"
              style={{
                verticalAlign: "middle",
                marginBottom: "6px",
                margin: "2px 2px 6px 2px",
              }}
            />
          ))
          : <CiFileOn className={"mt-0 mb-1 mr-1 inline-block w-4 h-4 align-middle"} />
        }
        {props.text}
      </p>
      {
        expanded && (
          <DrilldownContainer items={props.items} _depth={depth + 1} fullPath={props.fullPath ?? ""} />
        )
      }
    </div >
  );
}
